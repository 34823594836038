import React, { useState } from 'react'
import Image from 'next/image'
import { DomParser } from './domParser'
import getConfig from 'next/config'
import { client } from '../../apollo/client'
import { gql } from '@apollo/client/core'
import { ImageSizeQuery, ImageSizeQueryVariables } from './__generated__/img'

export const IMAGE_DIMENSIONS_QUERY = gql`
  query ImageSizeQuery($id: [QueryArgument]) {
    asset(id: $id) {
      width
      height
    }
  }
`

const fetchImageDimensions = async (id: string): Promise<ImageSizeQuery['asset']> => {
  const { data } = await client.query<ImageSizeQuery, ImageSizeQueryVariables>({
    query: IMAGE_DIMENSIONS_QUERY,
    variables: { id },
    context: {
      batch: true,
    },
  })

  return data.asset
}

export const img: DomParser = (element, { children, ...props }) => {
  const imageSrc = props.src as string
  const imageAlt = props.alt as string
  const { publicRuntimeConfig } = getConfig()
  const images = publicRuntimeConfig.images
  const [dimensions, setDimensions] = useState<ImageSizeQuery['asset']>({ width: props.width as number | undefined, height: props.height as number | undefined })

  const domainMatch = images?.domains.some(domain => imageSrc.includes(domain))
  const id = props['data-image']

  if (domainMatch && typeof id === 'string') {
    if ((!dimensions?.width || !dimensions?.height)) {
      fetchImageDimensions(id).then((dimensions) => {
        if (typeof dimensions?.height === 'number' && typeof dimensions?.width === 'number') {
          setDimensions(dimensions)
        }
      })
    }

    if (dimensions) {
      const { width, height } = dimensions

      return width && height ? (
        <Image src={imageSrc} alt={imageAlt} width={width} height={height} />
      ) : null
    } else {
      return null
    }
  }

  // eslint-disable-next-line jsx-a11y/alt-text, @next/next/no-img-element
  return <img { ...props } />
}