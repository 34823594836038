import { alpha, FormControlLabel, FormLabel } from '@material-ui/core'
import styled from 'styled-components'

export const StyledFormLabel = styled(FormLabel)`
  font-size: ${props => props.theme.typography.pxToRem(14)};
  color: ${props => props.theme.palette.text.secondary};
  padding-bottom: ${props => props.theme.typography.pxToRem(16)};
  transform: translate(${props => props.theme.typography.pxToRem(20)}, 0) scale(1);
  font-weight: 500;

  ${props=> props.theme.breakpoints.up('sm')} {
    font-size: ${props => props.theme.typography.pxToRem(16)};
    padding-bottom: ${props => props.theme.typography.pxToRem(19)};
  }

  &.Mui-focused {
    color: ${props => props.theme.palette.text.secondary};
  }
`

export const StyledFormControlLabel = styled(FormControlLabel)<{$outlined?: boolean}>`
  &.MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 0;
    border: solid ${props => props.$outlined ? props.theme.typography.pxToRem(1) : 0} ${props => alpha(props.theme.palette.text.secondary, 0.19)};
    border-radius: ${props => props.theme.typography.pxToRem(4)};

    > .MuiFormControlLabel-label {
      font-size: ${props => props.theme.typography.pxToRem(16)};
      color: ${props => props.theme.palette.text.secondary};

      ${props=> props.theme.breakpoints.up('sm')} {
        font-size: ${props => props.theme.typography.pxToRem(17)};
      }
    }
  }
`