// import styles
import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-thumbnail.css'
import 'lightgallery/css/lg-video.css'
import lgVideo from 'lightgallery/plugins/video'
//import LightGallery from 'lightgallery/react'
import dynamic from 'next/dynamic'
import { Video } from '../components/video/Video'
import { VideoContainer } from '../components/video/StyledVideo'

const LightGallery = dynamic(() => import('lightgallery/react'),{ ssr: false })

export type { VideoProps }  from '../components/video/Video'
export { LightGallery as default, lgVideo, Video, VideoContainer }