import { gql } from '@apollo/client'

export const IMAGE_FRAGMENT = gql`
  fragment ImageFragment on AssetInterface {
    url
    ... on images_Asset {
      alt
    }
  }
`

export const IMAGE_FALLBACK_FRAGMENT = gql`
  fragment ImageFallbackFragment on siteSettings_GlobalSet {
    newsFallbackImage {
      url
      ... on images_Asset {
        alt
      }
    }
  }
`