import React from 'react'
import { Button } from '../../components/button/button'
import { DomParser } from './domParser'

export const button: DomParser = (element, props, utils) => {
  const classes = utils.classes()

  if (classes.includes('btn-btn-black')) {
    return (
      <Button primary buttonColor="black" { ...props } />
    )
  }

  if (classes.includes('btn-btn-orange')) {
    return (
      <Button primary buttonColor="orange" { ...props } />
    )
  }

  if (classes.includes('btn-btn-green')) {
    return (
      <Button primary buttonColor="green" { ...props } />
    )
  }

  return element
}

button.match = (element) => element.name === 'button' && element.attribs.type !== 'submit'